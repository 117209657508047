import request from '@/utils/request'


// 查询直播分类列表
export function listClassify(query) {
  return request({
    url: '/live/classify/list',
    method: 'get',
    params: query
  })
}

// 查询直播分类分页
export function pageClassify(query) {
  return request({
    url: '/live/classify/page',
    method: 'get',
    params: query
  })
}

// 查询直播分类详细
export function getClassify(data) {
  return request({
    url: '/live/classify/detail',
    method: 'get',
    params: data
  })
}

// 新增直播分类
export function addClassify(data) {
  return request({
    url: '/live/classify/add',
    method: 'post',
    data: data
  })
}

// 修改直播分类
export function updateClassify(data) {
  return request({
    url: '/live/classify/edit',
    method: 'post',
    data: data
  })
}

// 删除直播分类
export function delClassify(data) {
  return request({
    url: '/live/classify/delete',
    method: 'post',
    data: data
  })
}
